.leaflet-pane,
.leaflet-tile,
.leaflet-marker-icon,
.leaflet-marker-shadow,
.leaflet-tile-container,
.leaflet-pane > svg,
.leaflet-pane > canvas,
.leaflet-zoom-box,
.leaflet-image-layer,
.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 30 !important;
}

.leaflet-div-icon {
  border: none;
  background-color: transparent;
}

.leaflet-container {
  overflow: hidden;
  z-index: 30 !important;
}

.leaflet-bottom {
  z-index: 30 !important;
}

#map {
  z-index: 0 !important;
}

.leaflet-popup-pane,
.leaflet-control {
  z-index: 30 !important;
}